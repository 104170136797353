<template>
  <div class="order_sub_bar flex-r-sb-c">
    <div class="left_btn flex-r-s-c">
      <div class="flex-c-s-s">
        <div class="order_money_item flex-r-c-c">
          <div style="width:1.5rem">实付金额:</div>
          <div style="color:#ED0D0D;">￥</div>
          <div style="color:#ED0D0D;font-size:0.48rem">{{ retotal }}</div>
        </div>
        <div v-if="coupon" class="order_money_item flex-r-c-c">
          <div style="width:3rem;color:#999999;font-size:0.32rem">优惠减￥{{ coupon }}</div>
        </div>
      </div>
    </div>
    <div class="right_btn flex-r-s-s">
      <div class="right_btn_out" @click="subOrder">提交订单</div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['retotal', 'coupon'],
  data() {
    return {
    }
  },
  methods: {
    subOrder() { this.$emit('subOrder') }
  }
}
</script>

<style lang="scss">
.order_sub_bar {
  position: fixed;
  width: 10rem;
  height: 1.71rem;
  background: #ffffff;
  opacity: 1;
  border-radius: 0rem;
  bottom: 0;
  .left_btn {
    width: 30%;
    padding-left: 0.35rem;
    .left_btn_item {
      font-size: 0.52rem;
      color: #686868;
    }
    .order_money_item {
      text-align: left;
      margin-left: 0.3rem;
      div {
        font-size: 0.32rem;
        color: #333333;
      }
    }
    span {
      font-size: 0.32rem;
      font-weight: 400;
      color: #686868;
    }
  }
  .right_btn {
    font-size: 0.37rem;
    line-height: 1.17rem;
    color: #ffffff;
    padding-right: 0.6rem;
    .right_btn_out {
      width: 2.8rem;
      height: 1.17rem;
      background: #0767ab;
      opacity: 1;
      border-radius: 0.59rem;
    }
  }
}
</style>
